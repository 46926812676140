html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  font-size: 8em;
  font-weight: bold;
  flex-direction: column;
}

@media screen and (max-width: 900px) {
  .App {
    font-size: 6em;
  }
}

@media screen and (max-width: 600px) {
  .App {
    font-size: 3em;
  }
}

h1 {
  font-size: 0.5em;
  margin-top: 0;
}
